import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ackumen from "../../Assets/Projects/ackumen.png";
import bmi from "../../Assets/Projects/bmi.png";
import chh from "../../Assets/Projects/chh.png";
import mi from "../../Assets/Projects/mi.png";
import mrbean from "../../Assets/Projects/mrbean.png";
import noritz from "../../Assets/Projects/noritz.png";
import procard from "../../Assets/Projects/procard.png";
import taxbharo from "../../Assets/Projects/taxbharo.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={noritz}
              title="Noritz Connect"
              description="Noritz Connect now gives you the ability to control your Noritz unit from your mobile device. Change temperature, check usage, or view heater health right from our app."
              androidLink="https://play.google.com/store/apps/details?id=com.noritz.iot&hl=en&gl=US"
              iosLink="https://apps.apple.com/us/app/noritz-connect/id1227949334"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ackumen}
              title="Buckman Ackumen"
              description="The Ackumen Platform combines state-of-the-art sensing technology and accurate predictive modeling to take the work—and the guesswork—out of managing your vital systems."
              androidLink="https://play.google.com/store/search?q=buckman&c=apps&hl=en_US"
              iosLink="https://apps.apple.com/in/app/ackumen/id1612106442"
              demoLink="https://www.buckman.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={taxbharo}
              title="Taxbharo"
              description="Taxbharo is a budding online platform to help us reach customers and help customers be aware of present day scenario of Taxation in India."
              demoLink="https://www.taxbharo.in/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={procard}
              title="Noritz Procard"
              description="Noritz PROCard members now have a fast and easy way to receive and access leads as well as register warranties and service calls."
              androidLink="https://play.google.com/store/apps/details?id=com.org.noritz&hl=en_US"
              iosLink="https://apps.apple.com/us/app/procard/id1110311645"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chh}
              title="Chhagan Ji Sweets"
              description="Chhagan Ji is your one-step destination for a mind-blowing range of exquisite sweets, snacks, biscuits, chocolates, and much more."
              demoLink="https://www.chhaganjisweet.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mi}
              title="Mi Tape"
              description="Maruti industries is one of the leading packing products & Adhesive Tape Manufacturers who is offering premium-quality products in India."
              demoLink="https://www.miwondertape.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bmi}
              title="BMI Calculator"
              description="BMI Calculator is a simple web app that helps you to calculate your BMI. It also gives you a healthy advice on how to lose weight and maintain your health."
              androidLink="https://play.google.com/store/apps/details?id=com.aftabali.bmicalculator&hl=en&gl=US"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mrbean}
              title="Mr Beans Pizza"
              description="Mr. Beans Pizza Dinein/Qsr module is a one step platform to serve multiple products under one roof like UNLIMITED ITALIAN Meal, Pizza, Frankie, Soft drinks, Ice creams,etc."
              demoLink="https://www.mrbeanspizza.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
