import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BsAndroid, BsApple, BsBrowserChrome } from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title style={{ fontWeight: "bold" }}>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}

        {props.androidLink && (
          <Button
            variant="primary"
            href={props.androidLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <BsAndroid /> &nbsp;
            {"Android"}
          </Button>
        )}
        {props.iosLink && (
          <Button
            variant="primary"
            href={props.iosLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <BsApple /> &nbsp;
            {"iOS"}
          </Button>
        )}
        {props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <BsBrowserChrome /> &nbsp;
            {"Website"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
